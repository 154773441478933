






















































































import { InputSetups } from '@/mixins/input-setups'
import ModuleConfigSettings from '../../components/ModuleConfigSettings.vue'
import ModuleConfigSettingsWrapper from '@/components/Modules/components/ModuleConfigSettingsWrapper.vue'
import FirstInactiveKickHandlerModuleBuilder
  from '@/includes/logic/Modules/models/modules/FirstInactiveKickHandlerModule/FirstInactiveKickHandlerModuleBuilder'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import Placeholders from '@/mixins/placeholders/placeholders'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'

import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import TimeUnitInput from 'piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue'
import { MediaEditorMode } from 'piramis-base-components/src/components/NewMessageEditors/types'
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    MultiMessageEditorWithMediaInput,
    TimeUnitInput
  },
  data() {
    return {
      MediaEditorMode,
      StepUnit,
    }
  },
})
export default class FirstInactiveKickHandlerModuleView extends Mixins(InputSetups, TariffsTagsHelper, Placeholders, ModuleBindings) {
  @VModel() module!: FirstInactiveKickHandlerModuleBuilder
}
